import React, { useCallback, useContext, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogActions from '@material-ui/core/DialogActions'
import MUIDialogContent from '@material-ui/core/DialogContent'
import MUIDialogTitle from '@material-ui/core/DialogTitle'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  ActivityLogContext,
  deleteRowAction,
  showDialogAction,
  fetchedDatatableAction,
  PERMISSIONS,
} from 'activity/ducks'
import useApi from 'common/hooks/useApi'
import useAppTitle from 'common/hooks/useAppTitle'
import { LayoutContext } from 'common/ducks'
import Dialog from 'activity/components/Dialog'

function serializeData(data = []) {
  return data.map((row) => [
    row?.id,
    row?.day,
    row?.name,
    row?.setNumber,
    row?.weight,
    row?.reps,
    row?.tempo,
    row?.calories,
    Number(row?.distance),
    row?.workTime,
    row?.restTime,
    row?.kpi ? 'true' : '',
    row?.rir,
    row?.tags,
    row?.programInfo,
  ])
}

function DataTable() {
  const endpoint = 'metrics/activities/'
  const [{ loading: listLoading }, list] = useApi(endpoint, { manual: true })
  const [{ loading: destroyLoading }, destroy] = useApi(
    { method: 'DELETE' },
    { manual: true }
  )
  const { state, dispatch } = useContext(ActivityLogContext)
  const { setAppLoading, setDrawerOptions } = useContext(LayoutContext)
  const viewDataAs = useSelector((globalState) => globalState.app.viewDataAs)
  const [index, setIndex] = useState(null)
  const [open, setOpen] = useState(false)

  const { count, data, permission } = state.datatable

  const fetchActivities = useCallback(
    async (page = 1) => {
      const response = await list({
        params: {
          page,
          user: viewDataAs === 'self' ? undefined : viewDataAs,
        },
      })

      dispatch(fetchedDatatableAction(response?.data))
    },
    [dispatch, list, viewDataAs]
  )

  const handleAdd = useCallback(() => dispatch(showDialogAction()), [dispatch])

  function handleDelete(idx) {
    setIndex(idx)
    setOpen(true)
  }

  async function handleDeleteConfirm() {
    setOpen(false)

    await destroy({ url: `${endpoint}${data[index].id}/` })

    dispatch(deleteRowAction(index))
    setIndex(null)
  }

  function handleDeleteCancel() {
    setIndex(null)
    setOpen(false)
  }

  function handleUpdate(idx) {
    return dispatch(showDialogAction({ index: idx, ...data[idx] }))
  }

  useAppTitle('Program')

  useEffect(() => {
    fetchActivities()
  }, [dispatch, fetchActivities, list, viewDataAs])

  useEffect(() => {
    setDrawerOptions({
      onAdd: handleAdd,
      showProgram: false,
    })
  }, [handleAdd, setDrawerOptions])

  useEffect(() => {
    setAppLoading(listLoading || destroyLoading)
  }, [listLoading, destroyLoading, setAppLoading])

  const columns = [
    {
      name: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'Day',
      options: {
        customBodyRender: (value) => moment(value).format('L'),
        display: true,
        searchable: true,
      },
    },
    {
      name: 'Name',
      options: {
        display: true,
        filterType: 'multiselect',
      },
    },
    {
      name: 'Set Number',
      options: {
        display: true,
      },
    },
    {
      name: 'Weight (lbs)',
      options: {
        display: true,
      },
    },
    {
      name: 'Reps',
      options: {
        display: true,
      },
    },
    {
      name: 'Tempo',
      options: {
        display: false,
      },
    },
    {
      name: 'Calories Burned',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'Distance (meter)',
      options: {
        display: false,
      },
    },
    {
      name: 'Work Time (seconds)',
      options: {
        display: false,
      },
    },
    {
      name: 'Rest Time (seconds)',
      options: {
        display: false,
      },
    },
    {
      name: 'KPI',
      options: {
        display: false,
      },
    },
    {
      name: 'RIR',
      options: {
        display: false,
      },
    },
    {
      name: 'Tags',
      options: {
        display: false,
      },
    },
    {
      name: 'Program Info',
      options: {
        display: false,
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (idx) => (
          <>
            <IconButton
              disabled={permission !== PERMISSIONS.WRITE}
              size="medium"
              onClick={() => handleUpdate(idx)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={permission !== PERMISSIONS.WRITE}
              size="medium"
              onClick={() => handleDelete(idx)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },
  ]

  const options = {
    count,
    filter: true,
    downloadOptions: { filename: 'activities.csv', separator: ',' },
    rowHover: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [],
    selectableRows: 'none',
    responsive: 'standard',
    serverSide: true,
    search: false,
    sort: false,
    sortOrder: { name: 'Day', direction: 'desc' },

    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          fetchActivities(tableState.page + 1)
          break
        default:
          break
      }
    },
  }

  return (
    <DataTable.Wrapper>
      <MUIDataTable
        columns={columns}
        data={serializeData(data)}
        options={options}
        title="Activities"
      />
      <MUIDialog open={open} onClose={handleDeleteCancel}>
        <MUIDialogTitle>Log Activity</MUIDialogTitle>

        <MUIDialogContent>
          Are you sure you want to delete this record?
        </MUIDialogContent>

        <MUIDialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} type="submit" color="secondary">
            Confirm
          </Button>
        </MUIDialogActions>
      </MUIDialog>

      <Dialog />
    </DataTable.Wrapper>
  )
}

DataTable.Wrapper = styled.div`
  .MuiTableCell-root{
    padding: 6px;
    text-align: center;
  }
  .MuiTableCell-head{
    background-color: #0b545e;
    color: white;
  }
  .MuiTableRow-root:nth-child(even){
    background-color: #dedede;
  }
`
export default DataTable
