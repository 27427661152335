import React from 'react'
import Dialog from 'activity/components/Dialog'
import { ActivityLogContext } from 'activity/ducks'
import Layout from 'common/components/Layout'
import ProgramDialog from 'activity/components/ProgramDialog'
import ProgramCalendar from 'activity/components/ProgramCalendar'
import useActivity from 'activity/hooks/useActivity'
import ProgramCards from 'activity/components/ProgramCards'

function Activity() {
  const { loading, data, state, dispatch, openProgramDialog, programList } =
    useActivity()

  return (
    <ActivityLogContext.Provider value={{ state, dispatch }}>
      <Layout>
        <ProgramCalendar loading={loading} data={data} />
        <ProgramCards programList={programList} onClick={openProgramDialog} />

        <Dialog />
        <ProgramDialog />
      </Layout>
    </ActivityLogContext.Provider>
  )
}

export default Activity
