/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback, useRef, useContext } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import * as Yup from 'yup'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Popover,
} from '@material-ui/core'
import CustomCheckbox from 'common/components/CustomCheckbox'
import Panel from './Panel'
import { DashboardCardsWrapper } from './styled'
import BackdropLoader from 'common/components/BackdropLoader'
import {
  ClearSingleBlockProgramExercise,
  commonActivityData,
  CreateProgramDaysAction,
  CreateProgramExerciseAction,
  DeleteProgramDaysAction,
  DeleteProgramExerciseAction,
  EditBlockData,
  FetchBlockData,
  FetchWeekGroupData,
  GetListBlocksAction,
  GetListGroupsAction,
  GetListProgramDaysAction,
  GetListProgramExerciseAction,
  GetListProgramWeeksAction,
  GetProgramDaysAction,
  GetProgramWeeksAction,
  GetListProgramAction,
  handleEditData,
  removeDayFromWeek,
  resetActivityAndGroup,
  UpdateBlocksAction,
  UpdateGroupsAction,
  UpdateProgramDaysAction,
  UpdateProgramExerciseAction,
  weekBlockModalData,
  weekDeleteEditedCommonData,
  weekEditActivityGroupUpdateDetailActivityData,
  weekEditBlockData,
  weekEditExcercize,
  GetListExerciseAction,
} from 'Redux/Action/programBuilderAct'
import { useDispatch, useSelector } from 'react-redux'
import CreateIcon from '@material-ui/icons/CreateRounded'
import DeleteIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import Add from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import BtnCellRenderer from '../../common/components/BtnCellRenderer.js'
import SelectRenderer from '../../common/components/selectRenderer.js'
import CheckboxRenderer from '../../common/components/checkboxRenderer.js'
import CopyRenderer from '../../common/components/copyRenderer.js'
import { LayoutContext } from 'common/ducks'
import useApi from 'common/hooks/useApi'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Dialog from '@material-ui/core/Dialog'
import CreateExercise from '../../common/components/CreateExercise.js'

const groupStyle = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  textAlign: 'center',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  color: '#0B545E',
  margin: '30px 0',
}

const addBlockStyle = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  textAlign: 'center',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  color: '#0B545E',
  margin: '30px 0',
  padding: '8px 16px 16px',
}

const headerStyle = {
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
}

const activityDetailName = [
  { label: 'Exercise', name: 'exercise' },
  { label: 'Set', name: 'set_number' },
  { label: 'Reps', name: 'reps' },
  { label: 'Weight (lbs)', name: 'weight_lbs' },
  { label: 'Notes', name: 'notes' },
  { label: 'Kpi', name: 'kpi' },
]
const activityDetailName2 = [{
    label: "Work Time (sec)", name: "work_time_seconds" },
    { label: "Rest Time (sec)", name: "rest_time_seconds" },
    { label: "Distance (m)", name: "distance_meters" },
    { label: "1RM%", name: "one_rep_max_percent" },
    { label: "RIR", name: "reps_in_reserve" },
    { label: "Tempo", name: "tempo" },
    { label: "Exercise Type", name: "ExerciseType" }]


const validationBlockSchema = Yup.object().shape({
  Name: Yup.string().required('Required'),
})

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

let checkList = []

const validationGroupSchema = Yup.object().shape({
    Name: Yup.string()
        .required('Required'),
    Block: Yup.array().notRequired(),
    Description: Yup.string()
        .notRequired(),
});

function WeekTabs({
  value,
  ExerciseData,
  BlockData,
  WeekData,
  GroupData,
  DayTitleData,
}) {
  const dispatch = useDispatch()
  const {
    GroupList,
    updateList,
    weekEditBlockExercise,
    blockInfo,
    groupInfo,
    programDayInfo,
    programWeekInfo,
    exerciseInfo,
    singleBlockExercise,
    programID,
  } = useSelector((state) => state.rootReducer.programBuilder)

  const [open, setOpen] = useState(false)
  const [openGroup, setOpenGroup] = useState(false)
  const [tableUpdated, setTableUpdated] = useState(false)

  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedGroupModalIndex, setSelectedGroupModalIndex] = useState(null)
  const [dayNumber, setdayNumber] = useState(null)
  const [blockDetails, setBlockDetails] = useState([])
  const [blockIndex, setBlockIndex] = useState(null)
  const [daysName, setDaysName] = useState('')

  const [openEditModal, setOpenEditModal] = useState(false)
  const handleOpenEditModal = () => setOpenEditModal(true)
  const handleCloseEditModal = () => setOpenEditModal(false)

  const [openEditBlockModal, setOpenEditBlockModal] = useState(false)
  const handleOpenEditBlockModal = () => setOpenEditBlockModal(true)

  const handleCloseEditBlockModal = () => {
    formikActivity.resetForm()
    formik.resetForm()
    setEditblockData([])
    setBlockDetails([])
    setOpenEditBlockModal(false)
//    setTimeout(() => {
//        dispatch(GetListProgramDaysAction())
//        }, 300)
  }

  const [openEditExerciseModal, setOpenEditExerciseModal] = useState(false)
  const handleOpenEditExerciseModal = () => setOpenEditExerciseModal(true)
  const handleCloseEditExerciseModal = () => setOpenEditExerciseModal(false)

  const [activityOpen, setActivityOpen] = useState(false)
  const [activityDetailId, setActivityDetailId] = useState(null)
  const [editCommonActivity, setEditCommonActivity] = useState([])
  const [editActivityOpen, setEditActivityOpen] = useState(false)
  const [openaActivityDetailName, setopenaActivityDetailName] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedActivityDetail, setSelectedActivityDetail] = useState([
    ...activityDetailName,
  ])
  const [selectedActivityDetail2, setSelectedActivityDetail2] =
    useState(activityDetailName2)
  const [activityGroupDetailId, setActivityGroupDetailId] = useState(null)
  const [tempactivityGroupAddActivity, setTempActivityGroupAddActivity] =
    useState({ Exercise: '', Set: '' })
  const [activityGroupOpen, setActivityGroupOpen] = useState(false)
  const [editCommonGroup, setEditCommonGroup] = useState([])
  const [weekBlockEditData, setWeekBlockEditData] = useState()
  const [addActivityGroupActivity, setAddActivityGroupActivity] =
    useState(false)
  const [editActivityGroupNameOpen, setEditActivityGroupNameOpen] =
    useState(false)
  const [
    activityGroupDetailActvityGroupIndex,
    setActivityGroupDetailActvityGroupIndex,
  ] = useState(null)
  const [editActivityGroupOpen, setEditActivityGroupOpen] = useState(false)
  const [activityGroupDetailActvityIndex, setActivityGroupDetailActvityIndex] =
    useState(null)
  const [editBlockData, setEditblockData] = useState({})
  const [weekDay, setWeekDay] = useState([])
  const [openAddEcerciseInEditButton, setOpenAddEcerciseInEditButton] =
    useState(true)
  const [openAddEcerciseInEdit, setOpenAddEcerciseInEdit] = useState(false)
  const [deleteIdProgramExerciseList, setDeleteIdProgramExerciseList] =
    useState(null)
  const id = openaActivityDetailName ? 'simple-popover' : undefined
  const [deleteGroupIdList, setDeleteGroupIdList] = useState(null)
  const [addedGroup, setAddedGroup] = useState([])
  const [addedBlock, setAddedBlock] = useState([])
  const [blockList, setBlockList] = useState([])
  const { setAppLoading, setDrawerOptions } = useContext(LayoutContext)
  const [deleteDayOpen, setDeleteDayOpen] = React.useState({open: false})
  const [duration, setDuration] = useState()

  const [ , blankGroup] = useApi(
            {
              method: 'POST',
              url: 'builder/program_days/blank_group/',
            },
            { manual: true }
          )

  const [ , blankBlock] = useApi(
            {
              method: 'POST',
              url: 'builder/groups/blank_block/',
            },
            { manual: true }
          )

  const [ , copyBlock] = useApi(
            {
              method: 'POST',
              url: 'builder/blocks/copy_block/',
            },
            { manual: true }
          )

  const [ , copyGroup] = useApi(
            {
              method: 'POST',
              url: 'builder/groups/copy_group/',
            },
            { manual: true }
          )

  const [ , copyDay] = useApi(
            {
              method: 'POST',
              url: 'builder/program_days/copy_day/',
            },
            { manual: true }
          )

  async function addBlankGroup() {
        const body = {id: selectedDay?.id}
        await blankGroup({
            data: body,
        }).then((response) => {
            if(response?.status === 200){
                dispatch(GetListProgramDaysAction())
                dispatch(GetListGroupsAction())
            }
        })
    }

  async function addBlankBlock() {
        const body = {id: selectedGroupModalIndex?.id}
        await blankBlock({
            data: body,
        }).then((response) => {
            if(response?.status === 200){
                setTimeout(() => {
                dispatch(GetListProgramDaysAction())
                }, 300)
//                dispatch(GetListProgramDaysAction())
                dispatch(GetListGroupsAction())
            }
        })
    }

    function handleCopyBlock() {
        formik.handleSubmit()
        const body = {id: editBlockData.id, source: 'main page'}
        setTimeout(() => {
            copyBlock({
                data: body,
            }).then((response) => {
            if(response?.status === 200){
                dispatch(GetListBlocksAction())
                formik.resetForm()
            }})
        }, 500)
    }

    function handleCopyGroup() {
        formikGroup.handleSubmit()
        const body = {id: selectedGroupModalIndex?.id, source: 'main page'}
        setTimeout(() => {
            copyGroup({
                data: body,
            }).then((response) => {
            if(response?.status === 200){
                dispatch(GetListGroupsAction())
                formikGroup.resetForm()
            }})
        }, 500)
    }

    function handleCopyDay(id) {
        const body = {id: id}
            copyDay({
                data: body,
            }).then((response) => {
            if(response?.status === 200){
                dispatch(GetListProgramDaysAction())
            }})
    }

  useEffect(() => {
    setAppLoading(programDayInfo.loading)
  }, [programDayInfo.loading, setAppLoading])


  const gridRef = useRef()

  useEffect(() => {
    let commonEditdata = weekEditBlockExercise
    let newEditActData = commonEditdata?.filter((data) => !data?.details)
    let newEditActGrpData = commonEditdata?.filter((data) => data?.details)
    setEditCommonActivity(newEditActData)
    setEditCommonGroup(newEditActGrpData)
  }, [weekEditBlockExercise])

  useEffect(() => {
    dispatch(GetListProgramAction())
  }, [])

  useEffect(() => {
    if (programDayInfo.data.length > 0) {
      const tempdata = programDayInfo.data.filter(
        (week) => week?.programWeek === value
      )
      setWeekDay(tempdata)
      if (open){
          if(selectedDay?.id) {
            const update = programDayInfo.data.filter(
                (day) => day?.id === selectedDay?.id
              )
            handleOpen(update[0])
          }
      }
      if (groupInfo.data.length > 0) {
          if (openGroup){
              if(selectedGroupModalIndex?.id) {
                const update = groupInfo.data.filter(
                    (group) => group?.id === selectedGroupModalIndex?.id
                  )
                  const list = update[0]?.blocks?.map((block)=> block.block || block)
                setBlockList(list)
              }
          }
      }
    }
  }, [programDayInfo, value])

  useEffect(() => {
    if (DayTitleData) {
      const weekId = WeekData?.find((week) => {
        return week.name === value
      })
      const tempData = []
      weekId?.programDays?.map((item) => {
        tempData[item?.name] = item?.groups
      })
      dispatch(FetchWeekGroupData(tempData))
    }
  }, [WeekData, value])

  useEffect(() => {
    setdayNumber(Object?.keys(weekDay).length)
  }, [weekDay])

  if (tableUpdated) {
    setTableUpdated(false)
  }

  const handleActivityGroupClose = () => {
    formikActivityGroup.resetForm()
    setActivityGroupOpen(false)
  }

  const handleActivityDropOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setopenaActivityDetailName(true)
  }

  const handleActivityDropClose = () => {
    setopenaActivityDetailName(false)
  }

  const handleActivityDetailNameChange = (data) => {
    const temp = [...selectedActivityDetail, data]
    const activityIndex1 = selectedActivityDetail2.findIndex(
      (item) => item.name === data.name
    )
    selectedActivityDetail2.splice(activityIndex1, 1)
    setSelectedActivityDetail(temp)
    handleActivityDropClose()
  }

  const handleRemoveActivity = () => {
    const temp1 = [...selectedActivityDetail]
    const temp2 = [...selectedActivityDetail2]
    if (temp1?.length > 2) {
      const lastData = temp1.pop()
      temp2.push(lastData)
      setSelectedActivityDetail2(temp2)
      setSelectedActivityDetail(temp1)
    }
  }

  const handleOpen = (item) => {
    setSelectedDay(item)
    setOpen(true)
    if (!groupInfo.loading && groupInfo.data.length == 0) {
        dispatch(GetListGroupsAction())
    }
    setDuration(item?.duration)
  }

  const handleGroupOpen = (index1) => {
    formikGroup.setFieldValue("Name", index1.group?.name)
    formikGroup.setFieldValue("SecondaryName", index1.group?.secondary_name || index1.group?.secondaryName)
    formikGroup.setFieldValue("Description", index1.group?.description)
    formikGroup.setFieldValue("Archived", index1.group?.archived)
    let tempBlock = []
    index1.group.blocks.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
        })
    index1.group.blocks.map((id)=>id?.id && tempBlock.push(id))
    setBlockList(tempBlock)
    formikGroup.setFieldValue("Block", tempBlock)
    setSelectedGroupModalIndex(index1?.group)
    setOpenGroup(true)
    if (!blockInfo.loading && blockInfo.data.length == 0) {
        dispatch(GetListBlocksAction())
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDuration()
  }

  const handleGroupClose = () => {
    formikGroup.resetForm()
    setOpenGroup(false)
  }

  const selectResource = (e, index) => {
    if (e.target.checked) {
      checkList.push({
        id: index,
        name: e.target.value,
        order: Number(index),
        blocks: [],
      })
    } else {
      const selectedIndex = checkList?.findIndex(
        (ele) => ele.name === e.target.value
      )
      checkList.splice(selectedIndex, 1)
    }
  }

  const selectBlockResource = (e, index, data) => {
    if (e.target.checked) {
      checkList.push(data)
    } else {
      const selectedIndex = checkList?.findIndex(
        (ele) => ele.name === data.name
      )
      checkList.splice(selectedIndex, 1)
    }
  }

  const removeDaysWeek = (dayListIndex) => {
    setdayNumber(dayNumber - 1)
    dispatch(DeleteProgramDaysAction(dayListIndex))
    setDeleteDayOpen({id: null, open: false})
  }

  const addDay = () => {
    if (programID){
        setdayNumber(Object?.keys(weekDay).length + 1)
        const day = `Day ${Object?.keys(weekDay).length + 1}`
        const templist = { ...weekDay }
        const payload = {
          groups_pk: [],
          groups: [],
          program_week: value,
        }
        let count = Object?.keys(templist)?.length
        const tempday = [
          {
            id: `${dayNumber}`,
            name: `Day ${dayNumber}`,
            order: `${dayNumber}`,
            groups: [],
          },
        ]
        if (count < 8) {
          dispatch(CreateProgramDaysAction(payload))
        }
    }
    else {
        alert('Select a Program First')
    }
  }

  const addDuration = () => {
        const ids = selectedDay.groups.map((ids, index) => ({'group': ids?.group, 'group_pk': ids?.group_pk, 'position': index, 'archived': ids?.archived}))
        const payload = {
          groups: ids,
          program_week: value,
          duration: duration,

        }
        dispatch(
          UpdateProgramDaysAction({ updateData: payload, id: selectedDay.id })
        )
        selectedDay.groups = ids
    }

  const addGroup = () => {
    if (addedGroup.id){
        const newGroup = {...addedGroup, 'group_pk': addedGroup.id, 'position': selectedDay.groups.length, 'archived': false}
        const groupList = [...selectedDay.groups, newGroup]
        const ids = groupList.map((ids, index) => ({'group': ids?.group, 'group_pk': ids?.group_pk, 'position': index, 'archived': ids?.archived}))
        const payload = {
          groups: ids,
          program_week: value,
          duration: duration,
        }
        dispatch(
          UpdateProgramDaysAction({ updateData: payload, id: selectedDay.id })
        )
        selectedDay.groups = ids
    }
  }

  const handleDeleteGroup = (id) => {
        setDeleteGroupIdList(id)
        const deletedGroupIndex = selectedDay?.groups?.findIndex((s) => s.group_pk === id)
        const editedDayDataList = selectedDay?.groups?.splice(deletedGroupIndex, 1);
        let groupList = selectedDay?.groups?.map((item, index) => ({
            'group_pk': Number(item.group_pk),
            'position': Number(index),
            'archived': item.archived,
        }))
        let payload = {
            groups: groupList,
            program_week: value,
        }
        dispatch(
          UpdateProgramDaysAction({ updateData: payload, id: selectedDay.id })
        )
//        setTimeout(() => {
//            dispatch(GetListProgramDaysAction())
//        }, 300)
        setDeleteGroupIdList()
    }

  const handleGroupDragEnd = (result) => {
        const tempValue = selectedDay.groups[result.source.index]
        selectedDay.groups.splice(result?.source?.index, 1)
        selectedDay.groups.splice(result?.destination?.index, 0, tempValue)
        selectedDay.groups = selectedDay?.groups?.map((item, index) => ({
            'group_pk': Number(item.group_pk),
            'position': Number(index),
            'group': {
                'blocks': item.group.blocks,
                'description': item.group.description,
                'id': item.group.id,
                'name': item.group.name,
            },
            'name': item.group.name,
            'archived': item.group.archived,

        }))
        let payload = {
            groups: selectedDay?.groups,
            program_week: value,
        }
        dispatch(
          UpdateProgramDaysAction({ updateData: payload, id: selectedDay.id })
        )
//        setTimeout(() => {
//            dispatch(GetListProgramDaysAction())
//        }, 300)
    }

  const addBlock = () => {
    if (addedBlock?.id){
        const newBlock = {...addedBlock, 'block_pk': addedBlock?.id, 'position': blockList.length + 1, archived: false}
        let blocks = [...blockList, newBlock]
        setBlockList(blocks)
        blocks = blocks?.map((ids, index) => ({'block_pk': ids.id, 'block': ids.id, 'position': index+1, 'archived': ids.archived, }))
            const payload = {
              blocks: blocks,
              description: formikGroup?.values?.Description,
              name: formikGroup?.values?.Name,
              secondary_name: formikGroup?.values?.SecondaryName || '',
              source: 'main page',
              archived: true,
            }
            dispatch(
              UpdateGroupsAction({
                updateData: payload,
                id: selectedGroupModalIndex?.id,
              })
            )
    }
  }

  const handleDeleteBlock = (id) => {
        const deletedBlockIndex = blockList?.findIndex((s) => s.id === id)
        blockList.splice(deletedBlockIndex, 1);
        let blocks = blockList?.map((ids, index) => ({'block_pk': ids.id, 'block': ids.id, 'position': index+1, archived: ids?.archived }))
        const payload = {
          blocks: blocks,
          description: formikGroup?.values?.Description,
          name: formikGroup?.values?.Name,
          secondary_name: formikGroup?.values?.SecondaryName || '',
          source: 'main page',
          archived: true,
        }
        dispatch(
          UpdateGroupsAction({
            updateData: payload,
            id: selectedGroupModalIndex?.id,
          })
        )
    }

  const handleBlockDragEnd = (result) => {
        const tempValue = blockList[result.source.index]
        blockList.splice(result.source.index, 1)
        blockList.splice(result.destination.index, 0, tempValue)
        let blocks = blockList?.map((ids, index) => ({'block_pk': ids.id, 'block': ids.id, 'position': index+1, archived: ids?.archived }))
            const payload = {
              blocks: blocks,
              description: formikGroup?.values?.Description,
              name: formikGroup?.values?.Name,
              secondary_name: formikGroup?.values?.SecondaryName || '',
              archived: true,
              source: 'main page',
            }
            dispatch(
              UpdateGroupsAction({
                updateData: payload,
                id: selectedGroupModalIndex?.id,
              })
            )
    }

    const [columnDefs2] = useState([
        {
            headerName: "Exercise",
            field: "exercise",
            cellStyle: {overflow: 'visible'},
            cellRenderer: SelectRenderer,
            cellRendererParams: (params) => {
                params.source = 'main page'
                return {data: params};
            },
            valueFormatter: (params) => {
                return params.value.name
            },
            rowDrag: true,
            pinned: 'left',
            width: 300,
        },
        { field: "set_number", headerName: 'Set', width: 100, editable: true, },

        { field: "reps", headerName: 'Reps', width: 100, editable: true, },
        { field: "weight_lbs", headerName: 'Weight (lbs)', width: 150, editable: true, },
        {
            field: "notes",
            headerName: 'Notes',
            width: 200,
            editable: true,
            cellEditorPopup: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
                rows: 20,
                cols: 20,
            }
        },
        { field: "kpi", headerName: 'KPI', width: 100, cellRenderer: CheckboxRenderer, },
        { field: "work_time_seconds", headerName: 'Work Time (s)', width: 125, editable: true, },
        { field: "rest_time_seconds", headerName: 'Rest Time (s)', width: 125, editable: true, },
        { field: "distance_meters", headerName: 'Distance (m)', width: 150, editable: true, },
        { field: "one_rep_max_percent", headerName: '1RM%', width: 100, editable: true, },
        { field: "reps_in_reserve", headerName: 'RIR', width: 100, editable: true, },
        { field: "tempo", headerName: 'Tempo', width: 100, editable: true, },
        {
          field: '',
          width: 75,
          cellRenderer: CopyRenderer,
        },
        {
          field: '',
          width: 75,
          cellRenderer: BtnCellRenderer,
        }
    ])

    const onRowValueChanged = useCallback((event) => {
        var data = event.data
        const payload = {
                id: data.id,
                exercise_pk: data.value.id,
                exercise: data.value.id,
                set_number: Number(data.set_number),
                weight_lbs: Number(data.weight_lbs),
                distance_meters: Number(data.distance_meters),
                reps: Number(data.reps),
                reps_in_reserve: Number(data.reps_in_reserve),
                one_rep_max_percent: Number(data.one_rep_max_percent),
                tempo: data.tempo,
                work_time_seconds: Number(data.work_time_seconds),
                rest_time_seconds: Number(data.rest_time_seconds),
                notes: data.notes,
                kpi: data.kpi,
                position: data.rowIndex,
                source: 'main page',
        }
        gridRef.current?.api.applyTransaction({ update: [data] })
        dispatch(UpdateProgramExerciseAction({ updateData: payload, id: data.id }))
  }, [])

    function onRowDragEnd(e) {
        let exercises_pk = []
        gridRef.current.api.forEachNode((rowNode, index) => {
            exercises_pk.push(rowNode.data.id)
        })
        let payload = {
            name: formik.values.Name,
            secondary_name: formik.values.SecondaryName || '',
            exercises_pk: exercises_pk,
            description: formik.values.Description,
            source: 'main page',
            archived: true,
        }
        dispatch(UpdateBlocksAction({ updateData: payload, id: editBlockData.id }))
    }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return
    }
    const tempData = { ...weekDay }
    if (result.destination.droppableId !== result.source.droppableId) {
      const sourceIndex = result.source.index
      const destinationIndex = result.destination.index
      const source =
        tempData[
          Number(
            Object?.keys(tempData).filter(
              (data) => tempData[data].id == result.source.droppableId
            )
          )
        ]
      const destination =
        tempData[
          Number(
            Object?.keys(tempData).filter(
              (data) => tempData[data].id == result.destination.droppableId
            )
          )
        ]
      const tempValue = source.groups[sourceIndex]
      destination.groups.splice(destinationIndex, 0, tempValue)
      source.groups.splice(sourceIndex, 1)

      tempData[result.source.droppableId] = source
      tempData[result.destination.droppableId] = destination
      const destinationGroups = destination.groups.map((data, index) => ({
            'group_pk': data?.group_pk,
            'group': data?.group,
            'position': index,
            'archived': true,
            }))
      const sourceGroups = source.groups.map((data, index) => ({
            'group_pk': data?.group_pk,
            'group': data?.group,
            'position': index,
            'archived': true,
            }))
      const payloadDrop = {
        groups: destinationGroups,
        program_week: destination?.programWeek,
      }
      const payloadDrag = {
        groups: sourceGroups,
        program_week: source?.programWeek,
      }
      dispatch(
        UpdateProgramDaysAction({
          updateData: payloadDrop,
          id: destination?.id,
        })
      )
      dispatch(
        UpdateProgramDaysAction({ updateData: payloadDrag, id: source?.id })
      )
    } else {
      const destinationIndex = result.destination.index
      const destination =
        tempData[
          Number(
            Object?.keys(tempData).filter(
              (data) => tempData[data].id == result.destination.droppableId
            )
          )
        ]
      dispatch(FetchWeekGroupData(tempData))
      setTableUpdated(true)
      const tempData2 = []
      DayTitleData?.map((item) => {
        const tempIndex = Object?.keys(tempData)?.indexOf(item.name)
        const tempItem = { ...item }
        if (tempIndex !== -1) {
          const tempKeyName = Object?.keys(tempData)[tempIndex]
          tempItem.groups = tempData[tempKeyName]
          tempData2.push(tempItem)
        }
      })
      const temp = destination.groups[result.source.index]
      destination.groups.splice(result.source.index, 1)
      destination.groups.splice(result.destination.index, 0, temp)
      const destinationGroups = destination.groups.map((data, index) => ({
          'group_pk': data?.group_pk,
          'group': data?.group,
          'position': index,
          'archived': true,
          }))

      const payloadDrop = {
        groups: destinationGroups,
        program_week: destination?.programWeek,
      }
      dispatch(
        UpdateProgramDaysAction({
          updateData: payloadDrop,
          id: destination?.id,
        })
      )
    }
//    setTimeout(() => {
//      dispatch(GetListProgramDaysAction())
//    }, 250)
  }
  const openEditBlockModalFun = (item, id, days, index, data, groupIndex) => {
    if (exerciseInfo.length == 0) {
            dispatch(GetListExerciseAction())
        }
    setWeekBlockEditData({
      groupIndex: groupIndex,
      dayId: days,
      blockId: index,
    })
    setActivityDetailId(data)
    setEditblockData(data)
    handleOpenEditBlockModal()
    setBlockIndex(index)
    formik.setFieldValue('Name', data?.name)
    formik.setFieldValue('SecondaryName', data?.secondary_name || data?.secondaryName)
    let blockTempArr = []
    data['exercises']?.forEach((exercise) => {
        blockTempArr.push({
            id: exercise.id,
            exercise: exercise.exercise,
            exercise_pk: exercise.exercise.id,
            set_number: exercise.set_number || exercise.setNumber || 0,
            weight_lbs: Number(exercise.weight_lbs) || Number(exercise.weightLbs) || 0,
            distance_meters: Number(exercise.distance) || Number(exercise.distance_meters) || Number(exercise.distanceMeters) || 0,
            one_rep_max_percent: Number(exercise.one_rep_max_percent) || Number(exercise.oneRepMaxPercent) || 0,
            reps: exercise.reps || 0,
            reps_in_reserve: exercise.reps_in_reserve || exercise.repsInReserve || 0,
            tempo: exercise.tempo,
            work_time_seconds: exercise.work_time_seconds || exercise.workTimeSeconds || 0,
            rest_time_seconds: exercise.rest_time_seconds || exercise.restTimeSeconds || 0,
            notes: exercise.notes,
            kpi: exercise.kpi,
            block_id: data.id,
            block_name: data?.name,
            block_secondary_name: data?.secondary_name || data?.secondaryName || '',
            block_description: data?.description,
            block_archived: data?.archived,
        })
    })
    formik.setFieldValue('selectActivityGroupF', blockTempArr)
    formik.setFieldValue('Description', data?.description)
    formik.setFieldValue('Archived', data?.archived)
    let newBlockItem = item?.filter((data) => data.id === id)
    setBlockDetails(blockTempArr)
    setDaysName(days)
  }

  const editActivityDetail = (data) => {
    setTimeout(() => {
      setActivityDetailId(data)
      setEditActivityOpen(true)
    }, 500)
  }

  const formikActivity = useFormik({
    enableReinitialize: true,
    initialValues: {
        exercise: "",
        set_number: null,
        weight_lbs: null,
        distance_meters: null,
        reps: null,
        reps_in_reserve: null,
        tempo: null,
        work_time_seconds: null,
        rest_time_seconds: null,
        ExerciseType: "",
        notes: null,
        one_rep_max_percent: null,
        kpi: false
    },
    onSubmit: (values, { resetForm }) => {
      let payload = {
                exercise_pk: values.exercise.id,
                set_number: Number(values.set_number),
                weight_lbs: Number(values.weight_lbs),
                distance_meters: Number(values.distance_meters),
                reps: Number(values.reps),
                reps_in_reserve: Number(values.reps_in_reserve),
                one_rep_max_percent: Number(values.one_rep_max_percent),
                tempo: values.tempo,
                work_time_seconds: Number(values.work_time_seconds),
                rest_time_seconds: Number(values.rest_time_seconds),
                notes: values.notes,
                kpi: values.kpi,
                block_id: activityDetailId.id,
                source: 'main page'
            }
      dispatch(CreateProgramExerciseAction(payload))
      values.exercise_pk = values.exercise
      formikActivity.values.set_number = values.set_number ? formikActivity.values.set_number + 1 : null
    },
  })

  useEffect(() => {
    if (singleBlockExercise.length > 0) {
        if (openEditBlockModal) {
            if (editBlockData.id) {
                let exerciseArray = singleBlockExercise.map((data) => ({
                    id: data.id,
                    exercise: data.exercise,
                    exercise_pk: data.exercisePk,
                    set_number: data.setNumber,
                    weight_lbs: Number(data.weightLbs),
                    distance_meters: Number(data.distanceMeters),
                    reps: data.reps,
                    reps_in_reserve: data.repsInReserve,
                    one_rep_max_percent: Number(data.oneRepMaxPercent),
                    tempo: data.tempo,
                    work_time_seconds: data.workTimeSeconds,
                    rest_time_seconds: data.restTimeSeconds,
                    notes: data.notes,
                    kpi: data.kpi,
                    block_id: editBlockData.id,
                }))
                gridRef.current?.api.applyTransaction({ add: exerciseArray })
                dispatch(ClearSingleBlockProgramExercise())
            }
        }
    }
  }, [singleBlockExercise])

  const formikGroup = useFormik({
        initialValues: {
            Name: "",
            SecondaryName: "",
            Block: [],
            Description: ""
        },
        onSubmit: (values, { resetForm }) => {

            selectedGroupModalIndex
            let blocks = blockList?.map((ids, index) => ({
                'block_pk': ids.id,
                'block': ids.id,
                'position': index+1,
                'archived': true }))
            const payload = {
              blocks: blocks,
              description: values?.Description,
              name: values?.Name,
              secondary_name: values?.SecondaryName || '',
              source: 'main page',
              archived: true,
            }
            dispatch(
              UpdateGroupsAction({
                updateData: payload,
                id: selectedGroupModalIndex?.id,
              })
            )
//            setTimeout(() => {
//                dispatch(GetListProgramDaysAction())
//            }, 300)
            handleGroupClose()
        },
        validationSchema: validationGroupSchema
    });

  const formik = useFormik({
    initialValues: {
      Name: '',
      SecondaryName: '',
      selectActivityGroupF: [],
      Description: '',
    },
    onSubmit: (values) => {
        let exercises_pk = []
        gridRef.current.api.forEachNode((rowNode, index) => {
            exercises_pk.push(rowNode.data.id)
        })
        let payload = {
            name: values.Name,
            secondary_name: values.SecondaryName || '',
            exercises_pk: exercises_pk,
            description: values.Description,
            source: 'main page',
            archived: true,
        }
        dispatch(
            UpdateBlocksAction({ updateData: payload, id: activityDetailId.id })
        )
//        setTimeout(() => {
//            dispatch(GetListProgramDaysAction())
//        }, 300)
        handleCloseEditBlockModal()
    },
//    validationSchema: validationBlockSchema,
  })

  const [checkedBlock, setCheckedBlock] = React.useState([])
  const [leftBlock, setLeftBlock] = React.useState()
  const [rightBlock, setRightBlock] = React.useState([])

  const leftCheckedBlock = intersection(checkedBlock, leftBlock)
  const rightCheckedBlock = intersection(checkedBlock, rightBlock)

  return (
    <div>
      {
        <>
          <DashboardCardsWrapper>
            <DragDropContext onDragEnd={handleDragEnd}>
              {weekDay.map((item, index) => {
                // DAY MAP
                return (
                  <Droppable droppableId={`${item.id}`} index={index}>
                    {(droppableProvided) => (
                      <Panel
                        style={{ headerStyle }}
                        className="box-cross"
                        variant="outlined"
                        header={`Day ${index + 1}`}
                        Index={index}
                        dayLength={weekDay.length}
                        removeDayWeek={() => removeDaysWeek(item.id)}
                        ref={droppableProvided?.innerRef}
                        {...droppableProvided?.droppableProps}
                      >
                        <CloseIcon
                          className="cross"
                          style={{
                            color: 'rgb(67, 129, 139)',
                            position: 'relative',
                            float:  'right',
                            top: '-60px',
                            cursor: 'pointer',
                          }}
                          onClick={() => setDeleteDayOpen({id: item.id, open: true})}
                        />
                        {dayNumber < 7 ? (
                          <ContentCopyIcon
                              style={{
                                color: 'rgb(67, 129, 139)',
                                position: 'relative',
                                float:  'right',
                                top: '-60px',
                                cursor: 'pointer',
                                marginRight: '10px'
                              }}
                              onClick={() => handleCopyDay(item.id)}
                        />
                        ) : (
                          <>
                          </>
                        )}
                        {
                          // GROUP MAP
                          item?.groups?.map((innerItem, index) => (
                            <>
                              <Draggable
                                key={`${innerItem?.group?.id}${item?.id}`}
                                draggableId={`${innerItem?.group?.id}${item?.id}`}
                                index={index}
                              >
                                {(draggableProvided, snapshot) => (
                                  <Panel
                                    style={{
                                      background: snapshot?.isDragging
                                        ? 'rgba(245,245,245, 0.75)'
                                        : 'none',
                                    }}
                                    ref={draggableProvided?.innerRef}
                                    {...draggableProvided?.draggableProps}
                                    {...draggableProvided?.dragHandleProps}
                                    variant="elevation"
                                    bodyPadding={3}
                                  >
                                    <Box>
                                      <>
                                        <Accordion style={{ boxShadow: 0 }}>
                                          <AccordionSummary
                                            sx={{
                                                pointerEvents: "none"
                                              }}
                                            expandIcon={
                                                <ExpandMoreIcon sx={{
                                                    pointerEvents: "auto"
                                                  }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <Box onClick={() => handleGroupOpen(innerItem, index)}>
                                              <span
                                                style={{
                                                  cursor: 'pointer',
                                                  fontWeight: 500,
                                                  fontSize: '14px',
                                                  textTransform: 'uppercase',
                                                }}
                                              >
                                                GROUP
                                              </span>
                                              <h2
                                                style={{
                                                  cursor: 'pointer',
                                                  fontWeight: 600,
                                                  color: '#0B545E',
                                                }}>
                                                {innerItem?.group?.name}
                                              </h2>
                                              <h3>{innerItem?.group?.secondary_name || innerItem?.group?.secondaryName} </h3>
                                            </Box>
                                          </AccordionSummary>
                                          {
                                            //BLOCK MAP
                                            innerItem?.group?.blocks?.map(
                                              (block, blockIndex) => (
                                                <>
                                                  <Box style={{padding: '8px 16px 16px'}}>
                                                    <Box>
                                                        <span
                                                            style={{
                                                              fontWeight: 500,
                                                              fontSize: '14px',
                                                              textTransform: 'uppercase',
                                                            }}
                                                          >
                                                            BLOCK
                                                          </span>
                                                      <h2
                                                        style={{
                                                          cursor: 'pointer',
                                                          fontWeight: 600,
                                                          color: '#0B545E',
                                                        }}
                                                        onClick={() =>
                                                          openEditBlockModalFun(
                                                            GroupList[item],
                                                            innerItem?.id,
                                                            item,
                                                            blockIndex,
                                                            block,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {block?.name}
                                                      </h2>
                                                      <h3
                                                        onClick={() =>
                                                          openEditBlockModalFun(
                                                            GroupList[item],
                                                            innerItem?.id,
                                                            item,
                                                            blockIndex,
                                                            block,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {block?.secondary_name || block?.secondaryName }
                                                      </h3>
                                                      <Box
                                                        style={{
                                                          fontWeight: 500,
                                                          marginTop: '1.3em',
                                                          fontSize: '14px',
                                                          marginLeft: '0.35em',
                                                          textTransform: 'uppercase',
                                                        }}
                                                      >
                                                        {' '}
                                                        EXERCISES
                                                      </Box>
                                                      {block.exercises?.map(
                                                        (data) => (
                                                          // EXERCISE MAP
                                                          <Box
                                                            onClick={() =>
                                                              openEditBlockModalFun(
                                                                GroupList[item],
                                                                innerItem?.id,
                                                                item,
                                                                blockIndex,
                                                                block,
                                                                index
                                                              )
                                                            }
                                                            style={{
                                                              marginLeft:
                                                                '2.5em',
                                                              display:
                                                                'list-item',
                                                            }}
                                                          >
                                                            {data.exercise?.name}
                                                          </Box>
                                                        )
                                                      )}
                                                    </Box>
                                                    <Box></Box>
                                                  </Box>
                                                </>
                                              )
                                            )
                                          }
                                          <Box
                                            className="add_block"
                                            style={{addBlockStyle}}
                                            onClick={() =>
                                              handleGroupOpen(innerItem, index)
                                            }
                                          >
                                            <a href="#"> + BLOCK </a>
                                          </Box>
                                        </Accordion>
                                      </>
                                    </Box>
                                  </Panel>
                                )}
                              </Draggable>
                            </>
                          ))
                        }

                        {droppableProvided?.placeholder}
                        <Box
                          className="add_group"
                          style={{groupStyle}}
                          onClick={() => handleOpen(item)}
                        >
                          <a href="#"> + GROUP </a>
                        </Box>
                      </Panel>
                    )}
                  </Droppable>
                )
              })}
            </DragDropContext>

            {/* ===============Edit-Block-Modal============== */}
            <Modal
              open={openEditBlockModal}
              onClose={handleCloseEditBlockModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              disableEnforceFocus
            >
              <Box
                style={{
                  backgroundColor: 'white',
                  border: '2px solid #000',
                  boxShadow: 24,
                  padding: '30px 30px 5px 30px',
                  minWidth: '75vw',
                  overflow: 'scroll',
                }}
              >
                <h3>Edit Block</h3>
                <form>
                  {
                    <>
                      <TextField
                        name="Name"
                        fullWidth
                        label="Name"
                        variant="filled"
                        value={formik.values.Name}
                        style={{ marginBottom: '10px' }}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="SecondaryName"
                        fullWidth
                        label="Secondary Name"
                        variant="filled"
                        value={formik.values.SecondaryName}
                        style={{ marginBottom: '10px' }}
                        onChange={formik.handleChange}
                      />
                    </>
                  }

                  <Box
                    style={{
                        backgroundColor: "white",
                        boxShadow: 24,
                        padding: '10px 0px 0px 0px',
                        minWidth: '75vw',
                        overflow: 'scroll',
                        maxHeight: '50vh',
                    }}
                >
                    <div className="ag-theme-alpine" style={{ height: '40vh', }}>
                      <AgGridReact
                        rowData={blockDetails}
                        getRowId={params => params.data.id}
                        rowHeight={50}
                        columnDefs={columnDefs2}
                        editType={'fullRow'}
                        rowDragManaged={true}
                        onRowValueChanged={onRowValueChanged}
                        animateRows={true}
                        onRowDragEnd={onRowDragEnd}
                        ref={gridRef}
                        stopEditingWhenCellsLoseFocus={true}
                        >
                      </AgGridReact>
                    </div>
                </Box>
                <Box style={{
                    backgroundColor: "white",
                    boxShadow: 50,
                    minWidth: '75vw',
                    overflow: 'scroll',
                    maxHeight: '50vh',
                }}>
                    <form onSubmit={formikActivity.handleSubmit} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        {<>
                            {selectedActivityDetail?.map((data) => (
                                <>
                                    {data?.name === "exercise" ? (
                                    <>
                                        <TableCell>
                                            <Autocomplete
                                              disablePortal
                                              value={formikActivity.values.exercise}
                                              options={exerciseInfo?.data?.map((option) => ({label: option.name, id: option.id}))}
                                              getOptionLabel={(option) => option?.label || ''}
                                              style={{width: '15vw'}}
                                              ListboxProps={{ style: { maxHeight: 300 } }}
                                              renderInput={(params) => <TextField {...params}  label="Exercise" variant='filled' />}
                                              onChange={(e, value) => formikActivity.setFieldValue("exercise", value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CreateExercise/>
                                        </TableCell>
                                    </>
                                    ) :
                                        data?.name === "reps" && data?.name === "weight_lbs" && data?.name === "set_number" && data?.name === "rest_time_seconds" && data?.name === "work_time_seconds" ? (
                                            <TableCell align="center">
                                                <TextField
                                                    type="number"
                                                    name={data?.name}
                                                    onChange={formikActivity.handleChange}
                                                    value={formikActivity.values[data?.name]}
                                                    style={{ width: "80px" }}
                                                    variant="filled"
                                                    placeholder={data?.label}
                                                />
                                            </TableCell>) :
                                            data?.name === "kpi" ? (
                                                <TableCell align="center">
                                                    <Checkbox
                                                        value={formikActivity.values.kpi}
                                                        name="kpi"
                                                        onChange={formikActivity.handleChange}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </TableCell>
                                            ) :
                                                data?.name === "notes" ? (
                                                <TableCell align="center">
                                                    <TextField
                                                        type="text"
                                                        name={data?.name}
                                                        placeholder={data?.label}
                                                        onChange={formikActivity.handleChange}
                                                        value={formikActivity.values[data?.name]}
                                                        style={{ width: "300px" }}
                                                        variant="filled"
                                                    />
                                                </TableCell>
                                                ) : (
                                                <TableCell align="center">
                                                    <TextField
                                                        type="number"
                                                        name={data?.name}
                                                        placeholder={data?.label}
                                                        onChange={formikActivity.handleChange}
                                                        value={formikActivity.values[data?.name]}
                                                        style={{ width: "100px" }}
                                                        variant="filled"
                                                    />
                                                </TableCell>
                                                )
                                    }
                                </>
                            )
                            )}
                            <TableCell align="center">
                                <Button type="submit" variant="contained" color="primary" onSubmit={formikActivity.handleSubmit}>ADD</Button>
                            </TableCell>
                            {/*<TableCell align="center">
                                <Button style={{ backgroundColor: 'transparent' }} variant="contained" onClick={handleClearActivity}>Clear</Button>
                            </TableCell>*/}
                        </>}
                    </form>
                </Box>

                  {/* Add or remove Column */}
                      {' '}
                      <Box style={{ marginTop: '0px' }}>
                        {selectedActivityDetail?.map((data) => (
                          <>
                            <Button
                              disabled
                              variant="contained"
                              size="small"
                              style={{ marginLeft: '10px' }}
                            >
                              {data.label}
                            </Button>
                          </>
                        ))}

                        <Button
                          aria-describedby={id}
                          style={{ marginLeft: '10px' }}
                          onClick={handleActivityDropOpen}
                        >
                          <Add />
                        </Button>
                        <Popover
                          id={id}
                          open={openaActivityDetailName}
                          anchorEl={anchorEl}
                          onClose={handleActivityDropClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          {selectedActivityDetail2?.map((data1) => (
                            <>
                              <MenuItem
                                onClick={() =>
                                  handleActivityDetailNameChange(data1)
                                }
                              >
                                {data1?.label}
                              </MenuItem>
                            </>
                          ))}
                        </Popover>
                        <Button onClick={handleRemoveActivity}>
                          <Remove />
                        </Button>
                      </Box>
                      <br />


                  {
                    <>
                      <TextField
                        name="Description"
                        fullWidth
                        label="Description"
                        variant="filled"
                        value={formik.values.Description}
                        onChange={formik.handleChange}
                        style={{ marginBottom: '10px' }}
                      />
                    </>
                  }

                  <Box style={{ textAlign: 'right', margin: '10px 0' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '10px' }}
                      onClick={handleCopyBlock}
                    >
                      Make Template
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: '10px' }}
                      onClick={formik.handleSubmit}
                    >
                      Save
                    </Button>
                    <Button
                      variant="text"
                      style={{ color: '#0000008A' }}
                      onClick={() => {
                        setOpenEditBlockModal(false)
                        dispatch(ClearSingleBlockProgramExercise())
                        formik.resetForm()
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </Box>
            </Modal>

            {/* ===============Edit-Day-Modal============== */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <Box style={{
                        backgroundColor: "white",
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: '30px',
                        minWidth: '50vw',
                        maxHeight: '90%',
                        maxWidth: '80%',
                        minHeight: '40%',
                    }}>

                 <div style={{display: 'flex'}}>
                     <h2>Edit Day</h2>
                     <div>
                        <TextField
                            name="Workout Duration"
                            value={duration}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setDuration(event.target.value);
                              }}
                            style={{ width: 200, marginRight: "10px", marginLeft: '35vw' }}
                            type="number"
                            label="Workout Duration (min)"
                            variant="outlined"
                        />
                        <Button variant="contained" color="primary" style={{ margin: '0px 20px', height: 55 }} onClick={addDuration}>
                            Save
                        </Button>
                      </div>
                    </div>
                    {/*GROUP LIST*/}
                    <Box style={{padding: '30px 0px', minHeight: '300px'}}>
                        <DragDropContext onDragEnd={handleGroupDragEnd}>
                            <Droppable droppableId='groupList'>
                                {(provided) => (
                                    <Box className='groupList' {...provided.droppableProps} ref={provided.innerRef}>
                                        {selectedDay?.groups?.map((row, index) => (
                                            <Draggable key={`${row?.group?.id}-${index}`} draggableId={`${row?.group?.id}`} index={index}>
                                                {(provided) => (
                                                    <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                        <Box className="card" style={{
                                                        minHeight: '50px',
                                                        margin: '5px 0px',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        paddingLeft: '30px',
                                                        flexDirection: 'row'
                                                        }}>
                                                            <h3 style={{margin: '0px', color: '#43818b'}}>{row?.group?.name}</h3>
                                                            <Button onClick={() => handleDeleteGroup(row?.group?.id)}>
                                                                <DeleteIcon style={{ color: "#43818b" }} />
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Box>
                    {/*ADD GROUP*/}
                    <div style={{ padding: '30px 0px'}}>
                        <Autocomplete
                          disablePortal
                          options={groupInfo?.data?.filter(option => !option?.archived).map((option) => ({label: option?.name, id: option?.id, name: option?.name, group: option,}))}
                          getOptionLabel={(option) => option?.label || ''}
                          style={{ width: '30vw', marginRight: '30px', display: 'inline-block', marginLeft: '10vw' }}
                          renderInput={(params) => <TextField {...params}  label="Select group to add" variant='filled' />}
                          onChange={(e, value) => setAddedGroup(value)}
                        />
                        <Button variant="contained" color="primary" style={{height: '55px', marginTop: '2%', marginRight: '3%'}} onClick={addGroup}>
                            Add
                        </Button>
                        <Button variant="outlined" color="primary" style={{height: '55px', marginTop: '2%', width: '80px'}} onClick={addBlankGroup}>
                            Add Blank
                        </Button>
                    </div>
              </Box>
            </Modal>

            {/* ======================EDIT-GROUP-MODAL======================= */}
            <Modal
              open={openGroup}
              onClose={handleGroupClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Box
                    style={{
                        backgroundColor: "white",
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: '30px',
                        minWidth: '65vw',
                        maxHeight: '90%',
                        maxWidth: '80%',
                    }}
                >
                    {
                        <h3>Edit Group</h3>
                    }
                    <form onSubmit={formikGroup.handleSubmit}>
                        <TextField
                            fullWidth
                            name="Name"
                            onChange={formikGroup.handleChange}
                            value={formikGroup.values.Name}
                            style={{ marginTop: "10px" }}
                            type="text"
                            label="Name"
                            variant="filled"
                            error={formikGroup.touched.Name && formikGroup.errors.Name}
                            helperText={formikGroup.touched.Name && formikGroup.errors.Name}
                        />
                        <TextField
                            fullWidth
                            name="SecondaryName"
                            onChange={formikGroup.handleChange}
                            value={formikGroup.values.SecondaryName}
                            style={{ marginTop: "10px" }}
                            type="text"
                            label="Secondary Name"
                            variant="filled"
                            error={formikGroup.touched.SecondaryName && formikGroup.errors.SecondaryName}
                            helperText={formikGroup.touched.SecondaryName && formikGroup.errors.SecondaryName}
                        />
                        <br />
                        {/*BLOCK LIST*/}
                        <Box style={{padding: '20px 0px', minHeight: '200px', maxHeight: '500px', overflow: 'scroll'}}>
                            <DragDropContext onDragEnd={handleBlockDragEnd}>
                                <Droppable droppableId='blockList'>
                                    {(provided) => (
                                        <Box className='blockList' {...provided.droppableProps} ref={provided.innerRef}>
                                            {blockList?.map((row, index) => (
                                                <Draggable key={`${row?.id}-${index}`} draggableId={`${row?.id}`} index={index}>
                                                    {(provided) => (
                                                        <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                            <Box className="card" style={{
                                                            minHeight: '50px',
                                                            margin: '5px 0px',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            paddingLeft: '30px',
                                                            flexDirection: 'row'
                                                            }}>
                                                                <h3 style={{margin: '0px', color: '#43818b'}}>{row?.name}</h3>
                                                                <Button onClick={() => handleDeleteBlock(row?.id)}>
                                                                    <DeleteIcon style={{ color: "#43818b" }} />
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                        )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Box>
                        {/*ADD BLOCK*/}
                        <div style={{padding: '10px 0px'}}>
                            <Autocomplete
                              disablePortal
                              options={blockInfo?.data.filter(option => !option.archived).map((option) => ({label: option?.name, id: option?.id, name: option?.name, block: option,}))}
                              getOptionLabel={(option) => option?.label || ''}
                              style={{ width: '75%', marginRight: '30px', display: 'inline-block', }}
                              renderInput={(params) => <TextField {...params}  label="Select block to add" variant='filled' />}
                              onChange={(e, value) => setAddedBlock(value)}
                            />
                            <Button variant="contained" color="primary" style={{height: '55px', marginTop: '3%',  marginRight: '3%'}} onClick={addBlock}>
                                Add
                            </Button>
                            <Button variant="outlined" color="primary" style={{height: '55px', marginTop: '3%', width: '80px'}} onClick={addBlankBlock}>
                                Add Blank
                            </Button>
                        </div>
                        {formikGroup.touched.Block && formikGroup.errors.Block && (
                            <FormHelperText error>
                                {formikGroup.errors.Block}
                            </FormHelperText>)}
                        <TextField
                            type="text"
                            name="Description"
                            fullWidth
                            onChange={formikGroup.handleChange}
                            value={formikGroup.values.Description}
                            multiline
                            rows={1}
                            label="Description"
                            variant="filled"
                            error={formikGroup.errors.Description && formikGroup.touched.Description}
                            helperText={formikGroup.touched.Description && formikGroup.errors.Description}
                        />
                        <Box style={{ textAlign: 'right', margin: '10px 0' }}>
                        <Button variant="outlined" color="primary" style={{ marginRight: '10px' }} onClick={handleCopyGroup}>
                            Make Template
                        </Button>
                        <Button variant="contained" color="primary" type="submit" style={{ marginRight: '10px' }} onSubmit={formikGroup.handleSubmit}>
                            Save
                        </Button>
                        <Button variant="text" style={{ color: '#0000008A' }} onClick={handleGroupClose}>
                            Cancel
                        </Button>
                    </Box>
                    </form>
                </Box>
            </Modal>
            {dayNumber < 7 ? (
              <Panel variant="outlined" onClick={() => addDay()} style={{minWidth: '15vw'}}>
                <Typography variant="h5">+ DAY</Typography>
              </Panel>
            ) : (
              <>
                <Panel variant="outlined" style={{ display: 'none' }}>
                  <Button disabled>+ DAY</Button>
                </Panel>
              </>
            )}
          </DashboardCardsWrapper>
        </>
      }
      <Dialog
        open={deleteDayOpen.open}
        onClose={() => setDeleteDayOpen({id: null, open: false})}
        PaperProps={{
            style: {
              width: '40vw'
            },
        }}
      >
        <Box
            style={{
            backgroundColor: "white",
            boxShadow: 24,
            padding: '30px',
            }}
        >
            <h2>Delete Day ?</h2>
            <Box style={{ textAlign: 'right', margin: '40px 0 10px 0 ' }}>
                <Button variant="contained" color="primary" onClick={() => removeDaysWeek(deleteDayOpen?.id)} style={{ marginLeft: "2vw", width: "10vw", border: "2px solid #0B545E" }}>
                    Yes
                </Button>
                <Button variant="text" style={{ color: '#0000008A' }} onClick={() => setDeleteDayOpen({id: null, open: false})}>
                    Cancel
                </Button>
            </Box>
        </Box>
    </Dialog>
    </div>
  )
}

export default WeekTabs
